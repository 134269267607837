//  全局样式调配器

// 文字颜色
.fn-txtc,.fn-selC{color:#7a8698;}
.fn-selC:hover{text-decoration: none;}

// 登录区链接颜色
.fn-extLinkC{color:#217DD9}

// 版本选择列表
.fn-txtOption a{color:#6B8798}
.fn-txtOption a:hover{background-color:#ecf5fa}

// 下拉箭头颜色
.fn-arrC{border-color:#fff;border-top-color:#217DD9}

// 导航样式切换
.mail-163 .item-163,
.mail-126 .item-126,
.mail-yeah .item-yeah,
.mail-vip163 .item-v163,
.mail-vip126 .item-v126,
.mail-vip188 .item-v188,
.mail-mobile .item-mobile{filter:alpha(opacity=100)!important;opacity: 1!important;}


.mail-163{
    // 导航箭头
    .ico-arr{top: 32px;}

    // 登录框
    .area-163{position:static!important;}


    // .main .panel .extra .styleMenu a:hover{background-color: #ecf5fa}

}

.mail-126{
    .ico-arr{top: 117px;}

    // 登录框
    .area-126{position:static!important;}

    // 文字颜色
    .fn-txtc,
    .fn-selC{color:#537a5b}

    // 登录区链接颜色
.fn-extLinkC{color:#01AB1D}

// 版本选择列表
.fn-txtOption a{color:#537A5B}
.fn-txtOption a:hover{background-color:#eff5eb}

// 下拉箭头颜色
.fn-arrC{border-color:#fff;border-top-color:#01AB1D}    
}
.mail-yeah{
    .ico-arr{top: 204px;}

    // 登录框
    .area-yeah{position:static!important}

    // 文字颜色
    .fn-txtc,
    .fn-selC{color:#7d7d7d}

    // 登录区链接颜色
    .fn-extLinkC{color:#DC1F26}

// 版本选择列表
.fn-txtOption a{color:#7D7D7D}
.fn-txtOption a:hover{background-color:#f1f1f1}

// 下拉箭头颜色
.fn-arrC{border-color:#fff;border-top-color:#DC1F26}        
}

.mail-vip163{
    .ico-arr{top: 32px;}
    .area-vip163 {
        position: static !important;
    }
}

.mail-vip126{
    .ico-arr{top: 117px;}
    .area-vip126 {
        position: static !important;
    }
}

.mail-vip188{
    .ico-arr{top: 204px;}
    .area-vip188 {
        position: static !important;
    }
}

.mail-mobile{
    .ico-arr{top: 290px;}

    // 登录框
    .area-mobile{display:block!important;}

    
    // 文字颜色
    .fn-txtc,
    .fn-selC{color:#7A8698}

    // 登录区链接颜色
    .fn-extLinkC{color:#217DD9}

    

    .extra{
        .free{display: none;}
        .mobile{display: block!important;}
    } 
    

}