@import "reset.css";

// 最小宽度

@minWidth:680px;
@bgimg:url('../img/global.png');

// 全局样式
a{color:#6B8798;text-decoration:none;
    &:hover{text-decoration: underline}
}

.ico{display:inline-block;vertical-align: middle;}
.ico-loading{width:16px;height: 16px;background:url('../img/loading_s.gif') no-repeat center;}


body{background-color:#4fa3f7;background-repeat: no-repeat;background-position: center;text-align: center;background-image: url("../img/bkground.jpg");font-size: 12px;background-size: cover;}
body.vip {
    background-image: url("../img/bkground-vip.jpg");
    background-color: #c39c65;
    .main .nav .item .logo {
        left: 34px;
    }
}

// 低版本浏览器提示
.overlay{position: absolute; top: 0; left: 0; width: 100%; height: 100%; background:#000; opacity:.6; filter:alpha(opacity=60); z-index: 999}
.overlay-tips{position: absolute; top: 50%; left: 50%; margin: -80px 0 0 -296px; width: 592px;height: auto;  text-align: left; z-index: 1000;background-color: #fff;text-align: center;font-size: 20px;color: #c00;
    p{padding:20px;line-height: 180%;}
}


/* header */
.header{
    height: 70px;width:100%;min-width: @minWidth;

    .inner{margin:0 auto;text-align: left;position: relative;
        padding: 0 30px;
        h1{float: left;  margin-top: 30px;}
        .ext{
            float:right;white-space: nowrap;padding-top: 30px;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            a{margin:0 10px;}
        }
    }

    .logo {
        display: block;
        width: 160px;
        height: 30px;
        background-image: url(../img/logo.png);
        background-size: 100% 100%;
        text-indent: -1000px;
        overflow: hidden;
    }

    a {
        color: #fff;
        text-decoration: none;
    }
}


// 主体内容区
.main{width: @minWidth;height:auto;min-height:344px;overflow: visible;margin:0 auto;text-align: left;margin-top:15%;position: relative;z-index: 10;

    // 口号
    h2{width: 298px;height: 26px;position: absolute;left: 0;top: -80px;color:#fff;font-size:24px;white-space: nowrap;overflow: hidden;}

    // 侧栏导航
    .nav{width: 220px;
        border-radius: 4px 0 0 4px;
        overflow: hidden;
        .item{background-color: #fff;filter: alpha(opacity=60);_filter: alpha(opacity=60);opacity: 0.6;width: 100%;height: 86px;border-bottom:1px solid #ccc;cursor: pointer;position: relative;
            &:hover{filter:alpha(opacity=80);_filter: alpha(opacity=80);opacity: 0.8;}

            .logo{display: block;width: 130px;height: 30px;background-repeat: no-repeat;position: absolute;left: 47px;top: 27px;}
            .logo-163{background-image: url(../img/logo-n-163.png);background-size: 142px 30px;width:142px;height: 30px;}
            .logo-126{background-image: url(../img/logo-n-126.png);background-size: 144px 30px;width:144px;height: 30px;}
            .logo-yeah{background-image: url(../img/logo-n-yeah.png);background-size: 128px 35px;width:128px;height: 35px;}
            .logo-v163{background-image: url(../img/logo-v163.png);background-size: 140px 30px;width:140px;height: 30px;}
            .logo-v126{background-image: url(../img/logo-v126.png);background-size: 150px 30px;width: 150px;height:  30px;}
            .logo-v188{background-image: url(../img/logo-v188.png);background-size: 135px 30px;width: 135px;height: 30px;}
            .logo-mobile{background-position: 0 -90px;width: 139px;height: 35px;}
        }
    }

    // 箭头
    .ico-arr{position: absolute;width: 16px;height: 24px;z-index: 10;left:204px;top:32px;transition: all .3s;background:url('../img/arr.png') no-repeat 0 0}

    // 登录主界面
    .panel{position: absolute;left: 220px;top:-35px;width: 460px;height: auto;min-height:300px;background-color: #fff;box-shadow: 0px 1px 5px RGBA(0,0,0,0.5);
        border-radius: 4px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.20);

        .area{min-height:300px;position: absolute;left:-1000px;top: -1000px;width: 100%;}
        .area-inited .loading{display: none;}

        .login-agreement {
            padding: 0 60px 20px;
            color: #666;
            a {
                color: #2D94D2;
            }
            .payBtn {
                display: inline-block;
                color: #666;
                line-height: 18px;
                margin-top: 4px;
            }
        }

        .loading{ height: 22px;font-size: 12px;color: #333;text-align: center;height: 100%;line-height: 256px;overflow: hidden;position: absolute;top: 0;left: 0;width:100%;background-color:#fff;filter:alpha(opacity=50);-moz-opacity:0.3;opacity:0.5; -khtml-opacity: 0.5;z-index: 10;}

        // 手机号码结构
        .area-mobile{text-align: center;
            .wraper{padding-top:45px;position: relative;width:340px;margin: 0 auto;text-align: left;}
            .hdmobile{display: block;width: 166px;height: 20px;background-position: 0 -150px;background-image:@bgimg;background-repeat: no-repeat;text-indent: -1000px;overflow: hidden;}
            .yixin{display: block;background-color:#4793E5;padding: 4px;font-size: 12px;font-weight: bold;color: #fff;position: absolute;left:180px;top:45px;white-space: nowrap;border-radius: 3px;}
            .ipt{width:100%;position: absolute;left: 0;top: 100px;border:1px solid #BAC7D2;height: 46px;font-size:18px;ime-mode:disabled;text-indent:46px;color: #333333;line-height: 46px;font-family:Arial, Helvetica, sans-serif;outline: none;background-image:@bgimg;background-repeat: no-repeat;background-position:-252px -137px;
                &:focus{border-color:#4AAFE9}
            }
            .error{position: absolute;left:4px;top:160px;color:#c00}
            .btn{display: block;width:100%;position:absolute;left: 0px;top:180px;background: #185C94;background-image: linear-gradient(#2374B3, #19588B);border-radius:4px;border:#19588B 1px solid;color:#fff; text-decoration:none; text-align: center;height: 44px;line-height: 44px; font-size: 18px;
                &:hover{background-image: linear-gradient(#116CB4, #024478)}
                &:active{background-image: linear-gradient(#024478, #116CB4)}
            }
        }
        // 拓展区：版本等设定
        .extra{width: 340px;margin: 0 auto;border-top: #C9D6DD 1px solid;position: relative;padding-top:11px;height: 32px;overflow: visible;
            // 隐藏选择区，默认设置
            display: none;
            .reg{float:right;}
            .ssl{float: right;white-space: nowrap;
                input{vertical-align: middle;}
            }
            .arr{width:0;height:0;border-width:4px;border-style: solid;}
            // 选择菜单
            .styleMenu{width: auto;height: auto;background-color: #fff;box-shadow: 0px 1px 5px rgba(0,0,0,0.5);white-space: nowrap;position: absolute;left:53px;top:2px;padding:3px;
                a{display: block;height: 24px;line-height: 24px;text-decoration: none;padding:0 8px;}
            }
            .mobile{display: none}
        }


        // 广告
        .ads{text-align: center;padding-bottom:10px;}

        // 错误提示
        .errTips{background-color: #000;opacity:0.8;padding:12px 0;text-align: center;color: #fff;font-size: 14px;position:absolute;top:1px;width:320px;height:30px;line-height:30px;left: 50%;margin-left: -160px;top:30%;border-radius: 10px;}

    }


}


// footer
.footer{position: absolute;bottom: 0;left:0;width:100%;min-width: @minWidth; text-align: center;
    .domain-list {
        margin: 0 auto;
        text-align: center;
        span {
            display: inline-block;
            background-size: 100% 100%;
            margin: 0 15px;
        }
        .logo-163 {
            width: 109px;
            height: 24px;
            background-image: url(../img/logo-163.png);
        }
        .logo-126 {
            width: 112px;
            height: 24px;
            background-image: url(../img/logo-126.png);
        }
        .logo-yeah {
            width: 111px;
            height: 30px;
            background-image: url(../img/logo-yeah.png);
        }
        .logo-vip163 {
            width: 135px;
            height: 30px;
            background-image: url(../img/logo-vip163-white.png);
        }
        .logo-vip126 {
            width: 135px;
            height: 27px;
            background-image: url(../img/logo-vip126-white.png);
        }
        .logo-vip188 {
            width: 135px;
            height: 30px;
            background-image: url(../img/logo-vip188-white.png);
        }
    }
    .copyright{height: 20px;line-height: 20px;color:rgba(255,255,255,0.6);overflow: hidden;
        margin-top: 12px;
        margin-bottom: 30px;
    }
    .expansion{width: @minWidth;height: 22px;line-height: 22px;border-top:1px solid #75b3d5;position:absolute;left: 50%;margin-left:-@minWidth/2;bottom:45px;
        .extText{width:100%;
            li{width:33%;float: left;text-align: left;
                span{margin-right: 3px;color:#a0c9e0 }                
                a{color: #fff;opacity: 0.5;filter: alpha(opacity=50); text-decoration:none;
                    &:hover{opacity: 0.8}
                }
            }
        }
    }
}

@import "allocator.less";                         
